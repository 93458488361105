import React from "react"
// import { Link } from "gatsby"

import { Container, Table } from "react-bootstrap"

// import BackgroundSection from "./background.js"

// import BackgroundImage from 'gatsby-background-image'

const pageInfo = () => (
    <span>
    <Container>
      <h1>Galvanized Hardware Cloth and Others</h1>
      <p>Galvanized Hardware Cloth is used for many industrial and commercial screening applications and is one of the most economical means of resisting rust and corrosion. Applications include screens for vents, louvers, spark arrestors, finger guards, baskets, racks, trays, cages, dehydrators and miscellaneous sheet metal fabrications.</p>
      <p><strong>Plus, the largest use now – Gopher Mesh!</strong></p>
    </Container>
    

    <Container>
        <Table striped bordered responsive className="noWrap">
          <thead>
          <tr>
              <th>Mesh Size</th>
              <th>Gauge</th>
              <th>Width x 100 ' Rolls</th>
              <th>Weight</th>
          </tr>
          </thead>
          <tbody>
              <tr>
                  <td>1/2" x 1/2"</td>
                  <td>19</td>
                  <td>24", 36", 48", 60", 72"</td>
                  <td>25 pounds per 100 square feet</td>
              </tr>
              <tr>
                  <td>1/4" x 1/4"</td>
                  <td>23</td>
                  <td>24", 36", 48"</td>
                  <td>24.5 pounds per 100 square feet</td>
              </tr>
              <tr>
                  <td>1/8" x 1/8"</td>
                  <td>27</td>
                  <td>36" and 48"</td>
                  <td>20.5 pounds per 100 square feet</td>
              </tr>
          </tbody>
      </Table>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vulputate, odio ut viverra scelerisque, magna metus euismod lacus, nec ullamcorper dolor leo ac magna. In non quam magna. Suspendisse quam nunc, malesuada in neque sed, venenatis iaculis ex. Aenean auctor diam a arcu malesuada, nec sollicitudin augue lacinia. Proin quam velit, sagittis vitae elementum non, laoreet quis libero. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vestibulum eget diam odio. Fusce ipsum velit, rutrum eu faucibus non, egestas id elit. Proin porttitor, augue condimentum laoreet semper, erat nunc faucibus ligula, sit amet imperdiet neque diam et metus. Mauris eget metus quis dolor bibendum lobortis id eget orci. Aliquam eu ipsum bibendum, aliquam felis nec, euismod nibh. Fusce commodo aliquet blandit. Donec vel quam porttitor, euismod ipsum ut, porta quam. Vestibulum at condimentum magna, a vulputate lorem. Curabitur at odio nulla.</p>
      
      <p>Integer quis viverra neque, et mollis dolor. Etiam non fringilla elit. Phasellus convallis orci quam, vel sodales lectus porta sed. Aliquam et orci varius, commodo risus ultricies, dictum augue. Fusce eget velit semper, rhoncus nisi sit amet, ornare purus. Nam vestibulum hendrerit dui et viverra. Nunc posuere pellentesque quam, in molestie nisl feugiat sit amet. Pellentesque consectetur, lacus sit amet egestas convallis, felis libero porttitor nunc, in aliquam dui risus et leo. Fusce ac hendrerit lectus, quis lobortis ligula. Sed tellus sapien, interdum nec auctor vel, elementum vel justo. Phasellus sagittis a urna ut rutrum. Sed quis dignissim est, ut suscipit elit. Praesent facilisis tincidunt mauris nec malesuada. Ut tempor tempus pretium. Aliquam erat volutpat. Integer leo mauris, fermentum et magna eget, eleifend dignissim magna.</p>
    </Container>
    </span>
)

export default pageInfo
